<template>
  <section class="paginate__all">
    <div class="overflow-auto">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        first-number
        last-number
      ></b-pagination>
    </div>
    <div class="d-flex align-items-center">
      <p class="small text-secondary pr-3">Показать на странице</p>
      <b-form-select
        class="px-2 mb-3 mr-5"
        style="width: 60px;"
        :options="pageOptions"
        v-model="perPage"
      ></b-form-select>
    </div>

    <div class="right__page">
      <p class="right__page__number">{{ currentPage * perPage }}</p>
      <p class="right__page__text">из</p>
      <p class="right__page__number" v-if="totalPage">{{ totalPage }}</p>
      <p class="right__page__number" v-if="totalPage2">{{ totalPage2 }}</p>
    </div>
  </section>
</template>
<script>
export default {
  props: ['totalPage', 'totalPage2'],
  data() {
    return {
      rows: 0,
      perPage: 10,
      currentPage: 1,

      pageOptions: [
        {
          value: 5,
          text: '5',
        },
        {
          value: 10,
          text: '10',
        },
        {
          value: 15,
          text: '15',
        },
        {
          value: 20,
          text: '20',
        },
      ],
    }
  },

  watch: {
    totalPage(value) {
      this.rows = value
    },
    totalPage2(value) {
      this.rows = value
    },
    currentPage(value) {
      this.$emit('current-page', value)
    },
    perPage(value) {
      this.$emit('per-page', value)
    },
  },
}
</script>
<style scoped lang="scss">
@import '../../sass/_colors.scss';

section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
  .left__page {
    display: flex;
    align-items: center;
    list-style: none;
    .second__item {
      color: $black2 !important;
    }
    .left__page__select {
      padding: 12px 23px 12px 16px;
      border: 1px solid $grey5;
      color: $black2;
      border-radius: 8px;
    }
    .left__page__text {
      color: $grey6;
      margin: 0 20px 0 26px;
      font-size: 18px;
    }
    .first__item {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    .left__page__item {
      padding: 14px;
      border: 1px solid $grey5;
      color: $grey6;
    }
  }
  .right__page {
    display: flex;
    align-items: center;
    .right__page__text {
      padding: 0 10px;
    }
    p {
      color: $grey6;
      font-size: 18px;
    }
  }
}
@media only screen and (max-width: 492px) {
  .pagination {
    padding-left: 0 !important;
  }
  .paginate__all {
    display: flex !important;
    flex-direction: column !important;
  }
}
</style>
