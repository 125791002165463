<template>
  <v-app>
    <section class="wrapper__navbar">
      <aside class="nav__side">
        <div class="aside__brand">
          <img src="../assets/group14.svg" alt="grup" />
        </div>
        <ul class="aside__list">
          <router-link to="/reports" class="aside__item aside__main">
            <img
              src="../assets/logo2.png"
              alt="logo2"
              class="aside__main__img"
            />
            <a href="#" class="aside__main__text">Reports</a>
          </router-link>
          <router-link to="/clients" class="aside__item">
            <a href="#" class="aside__link">Clients</a>
          </router-link>
          <router-link to="/notifications" class="aside__item">
            <a href="#" class="aside__link">News</a>
          </router-link>

          <router-link to="/settings" class="aside__item">
            <a href="#" class="aside__link">Settings</a>
          </router-link>
        </ul>
      </aside>
      <v-navigation-drawer v-model="drawer" absolute temporary>
        <v-list nav dense>
          <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4"
          >
            <ul class="aside__list aside__list2">
              <router-link to="/reports" class="aside__item aside__main">
                <img
                  src="../assets/logo2.png"
                  alt="logo2"
                  class="aside__main__img"
                />
                <a href="#" class="aside__main__text">Reports</a>
              </router-link>
              <router-link to="/clients" class="aside__item">
                <a href="#" class="aside__link">Clients</a>
              </router-link>
              <router-link to="/notifications" class="aside__item">
                <a href="#" class="aside__link">News</a>
              </router-link>

              <router-link to="/settings" class="aside__item">
                <a href="#" class="aside__link">Settings</a>
              </router-link>
            </ul>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <p></p>
      <nav class="navNavbar">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <h1 class="nav__title">Dashboard</h1>
          <div class="nav__toggle">
            <v-app-bar-nav-icon
              color="white"
              @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>
          </div>
        </div>
        <div>
          <router-view></router-view>
        </div>
      </nav>
    </section>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    group: null,
  }),

  watch: {
    group() {
      this.drawer = false
    },
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../sass/_colors.scss';
@import url('https://rsms.me/inter/inter.css');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.aside__list2 {
  margin-top: 100px;
}
.nav__toggle {
  display: none;
}
.wrapper__navbar {
  margin: 0 auto;
  display: flex !important;
  align-items: flex-start !important;
  justify-content: space-between !important;
  font-family: 'Inter';
  width: 100% !important;
  aside {
    width: 14%;
    top: 0;
    position: fixed;
    left: 0;
    z-index: 999;
    bottom: 0;
    height: 100% !important;
    background: $main;
    .aside__brand {
      display: flex;
      justify-content: center;
    }
    .aside__list {
      list-style: none;
      padding-left: 0 !important;
      .aside__main {
        display: flex;
        align-items: center;
        padding-left: 32px;
        padding-top: 18px;
        padding-bottom: 18px;
        a {
          font-size: 16px;
          text-decoration: none;
          margin-left: 12px;
          color: $grey;
        }
      }
      .aside2 {
        margin-top: 20px !important;
      }
      .aside__item {
        padding: 18px 0 18px 50px;
        display: block;
        margin: 5px 0;
        text-decoration: none;

        .aside__link {
          color: $grey;
          font-size: 16px;
          text-decoration: none;
        }
        &:hover,
        &.router-link-exact-active {
          background: $yellow;
          border-left: 2px solid $white;

          a {
            color: white !important;
          }
        }
      }
    }
  }
  nav {
    width: 86%;
    background: $main;
    height: 75px;
    .nav__title {
      color: $white;
      font-size: 24px;
      margin-left: 20px;
      margin-top: 30px;
      margin-bottom: 15px;
    }
    .nav__route {
      width: 96%;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 992px) {
    .nav__toggle {
      display: block;
      margin-top: 10px;
    }
  }
}
</style>
