<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@media only screen and (max-width: 1000px) {
  .nav__side {
    display: none;
  }
  .navNavbar {
    width: 100% !important;
  }
  .header__table {
    margin: 0 !important;
  }
  .box {
    width: 50vw !important;
    left: 10px !important;
  }
}
@media only screen and (max-width: 742px) {
  .header__input {
    width: 70vw !important;
    margin-top: 10px !important;
    margin-left: 20px !important;
  }
  .header__right {
    margin-left: 20px !important;
  }
  .header__items {
    flex-direction: column;
    .totalCashback {
      order: -1;
    }

    .header__left__input {
      width: 100% !important;
    }
    .header__right {
      margin-right: 15px !important;
      margin-top: 10px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .header__home {
    flex-direction: column;
    width: 100%;
  }
}
@media only screen and (max-width: 692px) {
  .box {
    width: 70vw !important;
    top: 100px !important;
  }
}
@media only screen and (max-width: 492px) {
  .box {
    top: 10px !important;
  }
  .chooser__image {
    display: flex !important;
    flex-direction: column !important;
  }
}
@media only screen and (max-width: 360px) {
  .header__right__text {
    font-size: 12px !important;
  }
}
</style>
