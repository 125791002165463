<template>
  <div class="container-wrapper">
    <div class="admin__first">
      <base-thedialog
        :show="!!error"
        title="An error ocured!!"
        @close="handleError"
      >
        <p>{{ error }}</p>
      </base-thedialog>
      <base-thedialog :show="isLoading" title="Authendicating..." fixed>
        <p>Authendicating ...</p>
        <base-spinner></base-spinner>
      </base-thedialog>
    </div>
    <div class="login__body">
      <div class="login__panel mx-auto">
        <p class="admin__paragraph">Admin Panel</p>
        <h2 class="h2">Log In to Admin Panel</h2>
        <p class="paragraph">Enter your phone number and password below</p>
        <b-form class="form__index" @submit.prevent="submitData">
          <b-form-group
            id="phone-number"
            label="Username"
            label-for="phone-input"
            label-size="lg"
          >
            <b-form-input
              id="phone-input"
              placeholder="Enter your username"
              type="text"
              required
              size="lg"
              v-model="user_name"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="password"
            label="PASSWORD"
            label-for="password-input"
            label-size="lg"
          >
            <b-form-input
              id="password-input"
              placeholder="Enter your password"
              required
              size="lg"
              v-model="password"
            ></b-form-input>
          </b-form-group>
          <b-button
            block
            type="submit"
            class="login__button"
            variant="light"
            size="lg"
          >
            Log In
          </b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSpinner from '../UI/BaseSpinner.vue'
export default {
  components: { BaseSpinner },

  data() {
    return {
      user_name: '',
      password: null,
      error: null,
      isLoading: false,
    }
  },

  methods: {
    handleError() {
      this.error = null
    },
    async submitData() {
      this.isLoading = true
      try {
        await this.$store.dispatch('login', {
          user_name: this.user_name,
          password: this.password,
        })
        this.$router.replace('/reports')
      } catch (err) {
        this.error =
          err.message ||
          'failed to authendicate , try  later check  your login data'
      }
      this.isLoading = false
    },
  },
}
</script>

<style scoped>
.admin__first {
  z-index: 111 !important;
}
.admin__main {
  width: 100%;
  display: flex;
  height: 100vh;
  justify-content: center;
}
.admin__box {
  background: white;
  position: fixed;
  width: 50%;
  border-radius: 30px;
  text-align: center;
  height: 30%;
  z-index: 999;
}
.container-wrapper {
  display: flex;
  position: relative;
}
.login__body {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center !important;
  flex-grow: 1;
  font-family: Inter, serif;
  background: #363740 !important;
}

.login__panel {
  position: relative;
  max-width: 382px;
  min-width: 200px;
  height: 581px;
  background: #ffa11e;
  border-radius: 8px;
}

.admin__paragraph {
  position: relative;
  margin-top: 100px;
  text-align: center;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.h2 {
  color: white;
  text-align: center;
  position: relative;
}

.paragraph {
  color: white;
  text-align: center;
  position: relative;
}

#phone-number {
  margin: auto 33px;
  color: white;
}

#password {
  margin: auto 33px;
  color: white;
}

.login__button {
  color: #ffa11e;
  align-self: center;
  margin: 40px 33px;
  width: 316px;
  cursor: pointer;
}

.circle {
  position: absolute;
  width: 172px;
  height: 172px;
  transform: rotate(-15deg);
  border-radius: 50%;
  top: 106px;
  left: 6px;
}

.little__circle {
  position: absolute;
  width: 117px;
  height: 117px;
  left: 135.71px;
  top: 137px;

  border-radius: 50%;
  transform: rotate(-15deg);
}

.circle2 {
  position: absolute;
  width: 172px;
  height: 172px;
  transform: rotate(-15deg);
  border-radius: 50%;
  top: 362px;
  left: 102px;
}

.little__circle2 {
  position: absolute;
  width: 117px;
  height: 117px;
  left: 243px;
  top: 402.77px;

  border-radius: 50%;
  transform: rotate(-15deg);
}

.form__index {
  position: relative;
}
</style>
