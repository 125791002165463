<template>
  <section>
    <v-app>
      <div v-if="!error">
        <div class="header__items">
          <div class="header__left">
            <div class="header__input">
              <input
                class="header__left__input"
                type="text"
                placeholder="Search Full Name"
                v-model="searched"
              />
            </div>
          </div>
          <h1 class="totalCashback">
            Total Balance: {{ this.$store.getters.totalCash }}
          </h1>
          <div class="header__right">
            <img
              src="../../assets/logo3.png"
              alt="logo"
              class="header__right__img"
            />
            <export-excel
              class="text-light ml-2"
              name="ClientsData.xls"
              :fields="fieldExcel"
              :fetch="downloadData"
            >
              Download Data
            </export-excel>
          </div>
        </div>
        <div v-if="dataLoading" @click="closeToggle2" class="backdrop"></div>
        <dialog open class="box" v-if="dataLoading">
          <base-spinner></base-spinner>
          <p class="box__spinner">Please wait it is downloading...</p>
        </dialog>
        <div v-if="dialog" @click="closeToggle" class="backdrop"></div>
        <dialog open v-if="dialog" class="box">
          <div class="box__header">
            <h1>Edit Client</h1>
            <svg
              @click="closeToggle"
              class="box__cancel"
              width="20"
              height="20"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.9259 13.257L14.925 13.2561L10.5116 9.00357L10.5113 9.00335L9.51468 8.05559L14.9249 2.85553C15.0384 2.74643 15.131 2.62479 15.1893 2.49028C15.2466 2.35812 15.2815 2.21384 15.2815 2.06842C15.2815 1.91603 15.2589 1.77033 15.1886 1.63436C15.1319 1.50406 15.0518 1.37152 14.9469 1.27072L14.947 1.27068L14.945 1.26896C14.8326 1.17173 14.7068 1.08334 14.5677 1.02763C14.428 0.971703 14.2672 0.95 14.1198 0.95C13.9694 0.95 13.8203 0.983375 13.6733 1.03773L13.6733 1.03771L13.672 1.03821C13.5329 1.09391 13.4066 1.1825 13.3038 1.2813L7.07441 7.26867C6.97003 7.369 6.8894 7.49108 6.83206 7.62334L6.83204 7.62334L6.83151 7.62466C6.77483 7.76631 6.73989 7.91024 6.73989 8.05579C6.73989 8.20121 6.77476 8.34549 6.83206 8.47765C6.8894 8.60992 6.97003 8.732 7.07441 8.83232L13.3038 14.8197L13.3038 14.8197L13.3048 14.8206C13.5241 15.0204 13.8117 15.1193 14.1088 15.1193C14.4083 15.1193 14.6958 14.9976 14.9029 14.7985C15.1211 14.5888 15.2372 14.3225 15.2374 14.0336C15.2487 13.7439 15.1326 13.4668 14.9259 13.257ZM13.6906 1.08463C13.8337 1.03174 13.9768 1 14.1198 1C14.2629 1 14.417 1.02116 14.5491 1.07405C14.6812 1.12694 14.8022 1.21157 14.9123 1.30677C15.0113 1.40198 15.0884 1.52892 15.1434 1.65586L13.6906 1.08463Z"
                fill="#363740"
                stroke="white"
                stroke-width="0.1"
              />
              <path
                d="M1.30557 2.81234L1.30649 2.81324L5.71987 7.06576L5.72011 7.06599L6.71677 8.01374L1.30653 13.2138C1.19302 13.3229 1.10048 13.4445 1.04217 13.5791C0.984874 13.7112 0.949995 13.8555 0.949995 14.0009C0.949995 14.1533 0.972543 14.299 1.04287 14.435C1.09954 14.5653 1.17965 14.6978 1.28452 14.7986L1.28447 14.7987L1.28646 14.8004C1.39886 14.8976 1.52468 14.986 1.66378 15.0417C1.80343 15.0976 1.96424 15.1193 2.1116 15.1193C2.26208 15.1193 2.41113 15.086 2.55817 15.0316L2.55817 15.0316L2.55942 15.0311C2.69851 14.9754 2.82487 14.8868 2.92767 14.788L9.15704 8.80066C9.26142 8.70033 9.34205 8.57826 9.39939 8.44599L9.39941 8.446L9.39993 8.44468C9.45662 8.30302 9.49156 8.15909 9.49156 8.01354C9.49156 7.86812 9.45668 7.72384 9.39939 7.59168C9.34205 7.45941 9.26142 7.33734 9.15704 7.23701L2.92767 1.24963L2.92768 1.24962L2.92668 1.24871C2.70733 1.04898 2.41974 0.950065 2.1226 0.950065C1.82314 0.950065 1.53568 1.0717 1.32854 1.27079C1.11034 1.48052 0.994286 1.74686 0.99402 2.03575C0.982723 2.32538 1.09882 2.60258 1.30557 2.81234Z"
                fill="#363740"
                stroke="white"
                stroke-width="0.1"
              />
            </svg>
          </div>
          <hr class="box__line" />
          <ul class="box__list">
            <li class="box__item1">
              <label>
                First Name
                <input
                  type="text"
                  placeholder="First Name"
                  v-model="firstName"
                />
              </label>
            </li>
            <li class="box__item1">
              <label>
                Last Name
                <input type="text" placeholder="Last Name" v-model="LastName" />
              </label>
            </li>
            <li class="box__item">
              <label>
                Balance
                <input type="text" placeholder="Balance" v-model="balance" />
              </label>
            </li>

            <li class="box__item">
              <label>
                Phone Number
                <input
                  type="text"
                  placeholder="+998 (__) ___-__-__"
                  v-model="phoneNumber"
                />
              </label>
            </li>
            <li class="box__item">
              <label>
                Status

                <select class="box__select" v-model="status">
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </label>
            </li>
          </ul>
          <button @click="sentData" type="button">
            Edit
          </button>
        </dialog>
        <div class="header__items__data">
          <v-data-table
            :headers="headers"
            loading-text="Loading... Please wait"
            hide-default-footer
            :loading="loading"
            :items="itemsWithIndex"
            class="elevation-1"
            @click:row="handleClick"
            :items-per-page="20"
            item-key="name"
          >
            <template #[`item.point_balance`]="{item}">
              {{ item.point_balance.toFixed(0) }}
            </template>
          </v-data-table>

          <the-pagination
            class="pagination"
            @current-page="paginationData"
            @per-page="perPage"
            :total-page="totalPage"
          ></the-pagination>
        </div>
      </div>
      <div v-if="error">
        <v-app>
          <v-col>
            <v-alert text prominent type="error" icon="mdi-cloud-alert">
              {{ error }}
            </v-alert>
          </v-col>
        </v-app>
      </div>
    </v-app>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import { baseUrl } from '../../constants/config'
import BaseSpinner from '../UI/BaseSpinner.vue'
import axios from 'axios'
export default {
  components: { BaseSpinner },
  data() {
    return {
      fieldExcel: {
        'First Name': 'first_name',
        'Last Name': 'last_name',
        'Sales ': 'sales',
        'Total Sales': 'total_sales',
        'Phone Number': 'phone_number',
        'Balance ': 'point_balance',
      },
      headers: [
        {
          text: 'N',
          align: 'left',
          value: 'index',
          sortable: false,
        },
        { text: 'Name', sortable: false, value: 'first_name' },
        { text: 'Surname', sortable: false, value: 'last_name' },
        { text: 'Phone Number', sortable: false, value: 'phone_number' },
        { text: 'Status', sortable: false, value: 'active' },
        { text: 'Balance', sortable: false, value: 'point_balance' },
      ],
      desserts: this.data,
      data: [],
      allData: [],
      loading: false,
      firstName: '',
      status: '',
      balance: '',
      sales: '',
      search: '',
      phoneNumber: '',
      count: null,
      LastName: '',
      currentPage: 1,
      totalPage: 0,
      page: 0,
      perpage: 10,
      searched: '',
      total_cashback: 0,
      total: 0,
      dialog: false,
      index: null,
      error: null,
      currentId: 0,
      pointBalance: '',
      dataLoading: false,
    }
  },
  mounted() {
    this.fetchingData()
    // console.log(localStorage.getItem('token'))
  },
  computed: {
    itemsWithIndex() {
      return this.data.map((items, index) => ({
        ...items,
        index: index + 1,
        active: 'active',
      }))
    },
  },
  watch: {
    searched(value) {
      this.fetchingData(value)
    },
  },
  methods: {
    handleClick(value) {
      this.ok(value)
    },
    ...mapActions(['numberFormat']),
    async sentData() {
      await this.postingData2()
    },
    async postingData2() {
      // console.log(this.currentId)
      const token = localStorage.getItem('token')
      await fetch(`${baseUrl}/clients/${this.currentId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          first_name: this.firstName,
          last_name: this.LastName,
          point_balance: this.balance,
          phone_number: this.phoneNumber,
          status: 'active',
        }),
      }).catch((error) => {
        console.log(error)
      })
      return
    },
    closeToggle() {
      this.dialog = false
    },
    closeToggle2() {
      this.dataLoading = false
    },
    ok(item) {
      this.firstName = item.first_name
      this.LastName = item.last_name
      this.balance = item.point_balance
      this.phoneNumber = item.phone_number
      this.sales = item.sales
      this.currentId = item._id
      this.pointBalance = item.point_balance
      this.status = 'active'
      this.dialog = true
    },
    async downloadData() {
      this.dataLoading = true
      const token = localStorage.getItem('token')
      await fetch(`${baseUrl}/clients?page=1&limit=${this.total}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.allData = data.data
        })
      // console.log('ok')
      this.dataLoading = false
      return this.allData
    },
    async perPage(val) {
      this.perpage = await val
      this.fetchingData()
    },
    async paginationData(value) {
      this.currentPage = await value
      this.fetchingData(value)
    },
    async fetchingData() {
      this.loading = true
      const token = localStorage.getItem('token')
      await axios
        .get(
          `${baseUrl}/clients?page=${this.currentPage}&limit=${this.perpage}&search=${this.searched}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((data) => {
          this.data = data.data.data
          this.total = data.data.total
          this.totalPage = data.data.total
          this.numberFormat(data.data.total_cashback, 0, '', ' ')
        })
        .catch((err) => {
          this.error = err.message || 'Please try again later...'
        })
      this.loading = false
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../sass/_colors.scss';
@import url('https://rsms.me/inter/inter.css');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.box__spinner {
  text-align: center;
  margin-top: 40px;
  font-weight: bold;
}
.totalCashback {
  font-size: 25px;
  color: #ffa11e;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(54, 55, 64, 0.3);
  backdrop-filter: blur(4px);
  z-index: 10;
}
.box {
  width: 35vw;
  margin: 0 auto;
  background: white;
  box-shadow: 5px 5px 20px rgb(90, 89, 89);
  position: relative;
  padding: 10px 20px 70px 20px;
  border-radius: 9px;
  position: fixed;
  z-index: 999;
  left: 13vw;
  top: 10vw;
  .box__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style-type: none;
    justify-content: space-between;
    font-family: 'Inter';
    padding-left: 0 !important;
    .box__item1 {
      width: 48%;
      margin-bottom: 18px;
      label {
        color: #646464;
        width: 100%;
        font-weight: bold;
        font-size: 12px;
      }
      input {
        margin-top: 5px;
        display: block;
        border: 2px solid #4b5b7a;
        border-radius: 4px;
        padding: 12px;
        width: 100%;
        &:focus {
          outline: none;
        }
      }
    }
    .box__item {
      width: 31%;
      margin-bottom: 18px;
      label {
        color: #646464;
        width: 100%;
        font-weight: bold;
        font-size: 12px;
      }
      input {
        display: block;
        border: 2px solid #4b5b7a;
        border-radius: 4px;
        padding: 12px;
        width: 100%;
        &:focus {
          outline: none;
        }
      }
      select {
        display: block;
        border: 2px solid #4b5b7a;
        border-radius: 4px;
        color: #646464;
        width: 100%;
        padding: 12px;

        &:focus {
          outline: none;
        }
      }
    }
  }
  .box__line {
    border: 2px solid #68768f;
    margin: 44px 0 22px 0;
  }
  .box__header {
    display: flex;
    align-items: center !important;
    justify-content: space-between;
    .box__cancel {
      cursor: pointer;
    }
  }
  h1 {
    color: #646464;
    padding: 12px;
    border-radius: 4px;
    margin: 0;

    font-size: 20px;
  }
  .box1 {
    display: block;
    margin-top: 5px;
    margin-bottom: 25px;
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    font-size: 17px;
    border-bottom: 1px solid grey;
  }
  .box2 {
    border-top: none;
    margin-bottom: 10px;
    outline: none;
    font-size: 17px;
    border-bottom: 1px solid grey;
    border-left: none;
    border-right: none;
    display: block;
    width: 100%;
  }
  div {
    display: flex;
    align-items: center;
    p {
      margin-right: 10px;
      margin-top: 15px !important;
    }
  }
  button {
    position: absolute;
    right: 20px;
    font-size: 15px;
    color: white;
    border: none;
    background: #ffa11e;
    padding: 13px 40px;
    cursor: pointer;
    bottom: 10px !important;
    font-family: 'Inter';
    border-radius: 10px;
    transition: background 0.4s;
    &:hover {
      background: $main;
    }
  }
}
.lds-ring {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #ffa11e;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ffa11e transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td {
  width: 15%;
  text-align: left;
  padding: 10px 0;
  border-radius: 5px;
}
.header4 {
  margin-left: 70px;
}

th {
  width: 15%;
  text-align: left;
}
.header5 {
  margin-left: 40px !important;
}
.header__main6 {
  margin-left: 40px !important;
}
.header__main2 {
  margin-left: -100px;
}
.header1 {
  margin-left: -100px;
}
.header__main5 {
  margin-left: 70px;
}
// .header__main6 {
//   margin-left: 80px;
// }
tr:nth-child(even) {
  background-color: $grey8 !important;
}
section {
  width: 97%;
  margin: 35px auto 0 auto;

  .header__box1 {
    background: $grey4;
    height: 45px;
    border-radius: 4px;
    margin-top: 9px;
  }
  .header__box {
    background: $grey4;
    height: 45px;
    border-radius: 4px;
    margin: 40px 0;
  }
  .header__items__data {
    margin-top: 20px;
    .header__items__main__list {
      display: flex;
      align-items: center;
      list-style: none;
      margin: 9px 20px 0 20px;

      // .header__main3 {
      //   // margin-right: 85px;
      // }
      // .header__main4 {
      //   // margin-right: 90px;
      // }
      // .header__main5 {
      //   // margin-right: 30px;
      // }
      li {
        color: $grey3;
        font-size: 14px;
        // width: 55px;
      }
      // .header__main2 {
      //   // margin-right: 55px;
      // }
    }
    .header__items__line {
      border: 1px solid $black;
      opacity: 30%;
    }
    .header__items__line2 {
      border: 1px solid $black;
      opacity: 30%;
      margin-top: 70px;
    }
    .header__items__brand__list {
      display: flex;
      align-items: center;
      margin: 30px 20px;
      list-style: none;
      li {
        color: $black;
        font-size: 14px;
        font-weight: bold;
        // margin-right: 40px;
      }
      // .header1 {
      //   // margin-right: 65px;
      // }
      // .header2 {
      //   // margin-right: 80px;
      // }
      .header {
        img {
          width: 17px;
        }
      }
      .header6 {
        display: flex;
        align-items: center;
        div {
          display: flex;
          flex-direction: column;
          button {
            border: none;
            background: transparent;
            margin: -3px 0;
            cursor: pointer;
          }
        }
      }
    }
  }
  .header__items {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header__left {
      display: flex;
      align-items: center;
      .header__input {
        width: 20vw;

        input {
          border: 1px solid $grey2;
          padding: 11px 11px 11px 15px;
          border-radius: 4px;
          width: 100%;
          &::placeholder {
            color: $grey;
            font-size: 13px;
          }
        }
      }
      div {
        margin-right: 20px;

        .header__left__select1 {
          border: 1px solid $grey2;
          padding: 11px 55px 11px 15px;
          border-radius: 4px;
          color: $grey;
        }
        .header__left__select2 {
          border: 1px solid $grey2;
          padding: 11px 24px 11px 15px;
          border-radius: 4px;
          color: $grey;
        }
        .header__left__select3 {
          border: 1px solid $grey2;
          padding: 11px 50px 11px 15px;
          border-radius: 4px;
          color: $grey;
        }
      }
    }
    .header__right {
      background: $yellow;
      display: flex;
      padding: 11px 16px;
      border-radius: 4px;
      cursor: pointer;

      align-items: center;
      button {
        margin-left: 8px;
        color: $white;
        border: none;
        background: transparent;
        font-size: 14px;
      }
    }
  }
}
</style>
