<template>
  <v-app>
    <div v-if="this.$store.getters.isLoading">
      <base-spinner></base-spinner>
      <p class="text-center">Please wait...</p>
    </div>
    <div v-if="!this.$store.getters.isLoading">
      <div class="container1">
        <div class="header__home">
          <v-col md="3" sm="8">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date1"
                  solo
                  label="Picker without buttons"
                  prepend-inner-icon="mdi-calendar-range"
                  transition="scale-transition"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date1"
                @input="menu1 = false"
                no-title
                scrollable
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="3" sm="8">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date2"
                  solo
                  label="Picker without buttons"
                  prepend-inner-icon="mdi-calendar-range"
                  transition="scale-transition"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date2"
                @input="menu2 = false"
                no-title
                scrollable
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="2" sm="8">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datu"
                  solo
                  label="Picker without buttons"
                  prepend-inner-icon="mdi-clock-time-four-outline"
                  transition="scale-transition"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-card>
                <v-list style="padding-top: 0;">
                  <v-radio-group
                    class="pt-1 mt-1"
                    v-model="options.custom"
                    hide-details
                  >
                    <v-list-item v-on:click="options.custom = false">
                      <v-list-item-action style="margin-right: 10px;">
                        <v-radio v-bind:value="false" />
                      </v-list-item-action>
                      <v-list-item-content>
                        {{ ('All Day') }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item v-on:click="options.custom = true">
                      <v-list-item-action style="margin-right: 10px;">
                        <v-radio v-bind:value="true" />
                      </v-list-item-action>
                      <v-list-item-content>
                        {{ ('Custom') }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-radio-group>
                </v-list>
                <v-card-text v-if="options.custom">
                  <v-layout>
                    <v-flex class="pr-1">
                      <v-combobox
                        style="width: 100px;"
                        v-model="start1"
                        :label="('start')"
                        v-bind:items="times1"
                      />
                    </v-flex>
                    <v-flex class="pl-1">
                      <v-combobox
                        v-model="end1"
                        style="width: 100px;"
                        :label="('end')"
                        v-bind:items="times2"
                      />
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-col>
          <!-- <v-col class="d-flex" cols="12" sm="2">
            <v-select
              :items="items"
              label="Solo field"
              prepend-inner-icon="mdi-store"
              solo
            ></v-select>
          </v-col>

          <v-col class="d-flex" cols="12" sm="2" mb="4" d-print-block>
            <v-select
              :items="items"
              label="Solo field"
              d-print-block
              prepend-inner-icon="mdi-account "
              solo
            ></v-select>
          </v-col> -->
        </div>
      </div>
      <div class="container2">
        <div class="header__center__left">
          <div class="header__left__top">
            <!-- <div>
              <h5>GROSS SALES</h5>
              <p>
                {{
                  Number(
                    this.$store.getters.getGross.gross_sales.toFixed(0),
                  ).toLocaleString()
                }}
              </p>
            </div>
            <span></span>
            <div>
              <h5>REFUNDS</h5>
              <p>
                {{
                  Number(
                    this.$store.getters.getGross.refunds.toFixed(0),
                  ).toLocaleString()
                }}
              </p>
            </div>
            <span></span>
            <div>
              <h5>DISCOUNTS</h5>
              <p>
                {{
                  Number(
                    this.$store.getters.getGross.discounts.toFixed(0),
                  ).toLocaleString()
                }}
              </p>
            </div>
            <span></span>
            <div>
              <h5>NET SALES</h5>
              <p>
                {{
                  Number(
                    this.$store.getters.getGross.net_sales.toFixed(0),
                  ).toLocaleString()
                }}
              </p>
            </div>
            <span></span> -->
            <div>
              <h5 @click="ok">USED CASHBACK</h5>
              <p>
                {{ usedCashback }}
              </p>
            </div>
            <span></span>
            <div>
              <h5>CASH BACKS</h5>
              <p>
                {{ cashback }}
              </p>
            </div>
          </div>
          <div class="header__left__top__second">
            <p>cash backs</p>
            <v-col sm="2" xs="4">
              <v-select
                v-model="select"
                :items="items2"
                item-text="state"
                item-value="abbr"
                label="Days"
                persistent-hint
                return-object
                single-line
              ></v-select>
            </v-col>
          </div>
          <div class="header__left__center small">
            <area-chart></area-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="container3">
      <div class="header__left__bottom">
        <v-card>
          <v-card-title>
            Top Searched
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :loading="load"
            :items="this.desserts"
            :search="search"
          ></v-data-table>
        </v-card>
      </div>
      <div class="header__left__second__bottom">
        <v-card>
          <v-card-title>
            Top Clients
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search2"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers2"
            :loading="load"
            :items="this.desserts2"
            :search="search2"
          >
            <template #[`item.point_balance`]="{item}">
              {{ item.point_balance.toFixed(0) }}
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
  </v-app>
</template>
<script>
// import { mapGetters } from 'vuex'
// import { VDatetimePicker } from 'v-datetime-field'
// import BaseSpinner from '../components/UI/BaseSpinner.vue'
// import axios from 'axios'
export default {
  // component: { BaseSpinner },
  mounted() {
    this.menu2 = false
    this.load = true
    this.$store.dispatch('fetchingData')
    this.$store.dispatch('topSearched', {
      startTime: new Date(this.date1).getTime(),
      endTime: new Date(this.date2).getTime(),
    })
    this.load = false
    this.$store.dispatch('grossSales', {
      start: new Date(this.date1).getTime(),
      end: new Date(this.date2).getTime(),
      type: 2,
    })
  },
  computed: {
    usedCashback() {
      return Number(
        this.$store.getters.getGross.used_cashback.toFixed(0),
      ).toLocaleString()
    },
    cashback() {
      return Number(
        this.$store.getters.getGross.cash_back.toFixed(0),
      ).toLocaleString()
    },
    desserts() {
      return this.$store.getters.topSearchedGet.data
    },
    desserts2() {
      return this.$store.getters.getFetch
    },
    datu() {
      if (this.options.custom == false) {
        return 'All Day'
      } else {
        return this.dax
      }
    },
    dax() {
      return this.start1 + ' : ' + this.end1
    },
  },
  data() {
    return {
      start1: '12 AM',
      end1: '12 PM',
      times1: [
        '12 AM',
        '1 AM',
        '2 AM',
        '3 AM',
        '4 AM',
        '5 AM',
        '6 AM',
        '7 AM',
        '8 AM',
        '9 AM',
        '10 AM',
        '11 AM',
        '12 AM',
        '1 PM',
        '2 PM',
        '3 PM',
        '4 PM',
        '5 PM',
        '6 PM',
        '7 PM',
        '8 PM',
        '9 PM',
        '10 PM',
        '11 PM',
      ],
      times2: [
        '12 AM',
        '1 AM',
        '2 AM',
        '3 AM',
        '4 AM',
        '5 AM',
        '6 AM',
        '7 AM',
        '8 AM',
        '9 AM',
        '10 AM',
        '11 AM',
        '12 AM',
        '1 PM',
        '2 PM',
        '3 PM',
        '4 PM',
        '5 PM',
        '6 PM',
        '7 PM',
        '8 PM',
        '9 PM',
        '10 PM',
        '11 PM',
      ],
      options: {
        custom: false,
        start: '',
        end: '',
      },
      search: '',
      headers: [
        {
          text: 'Barcode',
          align: 'start',
          sortable: false,
          value: 'barcode',
        },
        { text: 'Count', value: 'count' },
        { text: 'Found count', value: 'found_count' },
        { text: 'Notfound count', value: 'notfound_count' },
      ],
      headers2: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'first_name',
        },
        { text: 'Surname', value: 'last_name' },
        { text: 'Phone Number', value: 'phone_number' },
        { text: 'Balance', value: 'point_balance' },
      ],

      chartData: {
        Books: 24,
        Magazine: 30,
        Newspapers: 10,
      },
      loading: this.$store.getters.isLoading,
      select2: { state: 'Florida', abbr: 'FL' },
      items2: [
        { state: 'Hours', abbr: '1' },
        { state: 'Days', abbr: '2' },
        { state: 'Weeks', abbr: '3' },
        { state: 'Months', abbr: '4' },
        { state: 'Years', abbr: '5' },
      ],

      items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      date1: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
        .toISOString()
        .substr(0, 10),
      // date2: '',
      menu1: '',
      menu2: '',
      date01: '',
      date02: '',
      search2: '',
      date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      select: {
        state: '',
        abbr: '',
      },
      load: false,
    }
  },
  created() {},
  watch: {
    select() {
      this.ok()
    },
    date1() {
      this.on()
    },
    date2() {
      this.on()
    },
    datu() {
      this.on()
    },
  },
  methods: {
    convertTime12To24(time) {
      var hours = Number(time.match(/^(\d+)/)[1])
      var AMPM = time.match(/\s(.*)$/)[1]
      if (AMPM === 'PM' && hours < 12) hours = hours + 12
      if (AMPM === 'AM' && hours === 12) hours = hours - 12
      var sHours = hours.toString()
      if (hours < 10) sHours = '0' + sHours
      return sHours
    },
    on() {
      this.$store.dispatch('grossSales', {
        start: new Date(this.date1).getTime(),
        end: new Date(this.date2).getTime(),
        type: 2,
        before: Number(this.convertTime12To24(this.start1)),
        after: Number(this.convertTime12To24(this.end1)),
      })
    },
    ok2() {
      this.$refs.menu2.save(this.date2)
      this.$store.dispatch('grossSales', {
        start: new Date(this.date1).getTime(),
        end: new Date(this.date2).getTime(),
        type: 2,
      })
    },
    ok() {
      // console.log(new Date(this.date1).getTime())
      console.log(this.date1)
      this.$store.dispatch('grossSales', {
        start: new Date(this.date1).getTime(),
        end: new Date(this.date2).getTime(),
        type: Number(this.select.abbr),
      })
    },

    diagram() {
      this.chartdata.datasets.data = this.$store.getters.getGross.data
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../sass/_colors.scss';
@import url('https://rsms.me/inter/inter.css');
// .v-menu__content {
//   left: 200px !important;
// }
.header__left__second__bottom {
  margin-top: 10px;
}
.page-item.active .page-link {
  color: red !important;
}
.container3 {
  width: 96%;
  margin: 0 auto;
}
section {
  background: $black3 !important;
  border: 1px solid transparent;
  font-family: 'Inter';
  .container1 {
    margin: 0 auto;
    width: 96%;
    margin: 0 auto;
    .header__home {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .header__home__left {
        width: 25%;
        input {
          width: 100%;
          background: $yellow2;
          background: rgba(255, 161, 30, 0.19);
          border-radius: 4px;
          padding: 11px 12px 11px 13px;
          border: none;
          &::placeholder {
            color: $grey;
          }
        }
      }
      .header__home__right {
        display: flex;
        align-items: center !important;
        .header__home__line {
          margin: 0 26px 0 21px;
          color: black;
          opacity: 20%;
          font-size: 23px;
        }
        .header__home__text {
          color: $main;
          font-size: 12px;
          margin-top: 12px;
        }
        .header__home__down {
          margin: 0 19px 0 9px;
        }
      }
    }
  }
  .container2 {
    width: 96%;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: flex-start;
    .header__center__left {
      width: 100%;
      .header__left__top__second {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: white;
        padding: 0 20px;
        p {
          color: #797979;
        }
      }
      .header__left__top {
        display: flex;
        background: white;
        justify-content: space-between;
        align-items: center;
        padding-left: 38px;
        padding-right: 38px;
        padding-top: 22px;
        padding-bottom: 22px;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        span {
          margin: 0 5px 0 5px;
          background-color: black;
          width: 2px;
          height: 60px;
          opacity: 20%;
        }
        div {
          p {
            color: blacrgba(0, 0, 0, 0.87) k;
            text-align: center;
            font-weight: bold;
            font-size: 14px;
          }
          h5 {
            color: rgba(0, 0, 0, 0.6);
            font-size: 0.875rem;
            text-align: center;
          }
        }
      }
      .header__left__center {
        display: flex;
        background: white;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        margin-bottom: 25px;
        div {
          width: 100%;
        }
        .header__left__center__left {
          p {
            color: $grey;
            font-size: 10px;
            margin-bottom: 44px;
          }
        }
        .header__left__center__right {
          display: flex;
          flex-direction: column;
          .header__graph {
            margin-top: 30px;
          }
          .header__info {
            display: flex;
            align-items: center;
            margin-top: 25px;
            justify-content: space-between;
            p {
              color: $grey;
              font-size: 10px;
            }
          }
        }
      }
      .header__left__bottom {
        padding: 26px 37px 31px 26px;
        background: white;
        border-radius: 4px;
        margin-bottom: 114px;

        .header__left__bottom__title {
          color: black;
          font-size: 15px;
          margin-bottom: 42px;
        }
        .header__left__bottom__list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 22px;
          & > p {
            margin-right: 26px;
            color: black;
            font-weight: bold;
            font-size: 11px;
          }
          div {
            display: flex;
            align-items: center;
            img {
              visibility: hidden;
              margin-right: 6px;
            }
            p {
              color: black;
              font-weight: bold;
              font-size: 11px;
              width: 100px;
            }
            .header__initiator {
              width: 140px !important;
            }
          }
        }
        .header__left__bottom__list__data {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 25px;

          //   & > p {
          //     font-size: 11px;
          //     color: #56e344;
          //     background: rgba(86, 227, 68, 0.5);
          //     padding: 4px 6px;
          //     text-align: center;
          //     width: 65px;
          //     border-radius: 61px;
          //   }
          .header__left__success {
            font-size: 11px;
            color: #56e344;
            background: rgba(86, 227, 68, 0.5);
            padding: 4px 6px;
            text-align: center;
            width: 65px;
            border-radius: 61px;
          }
          .header__left__pending {
            font-size: 11px;
            color: $yellow2;
            background: rgba(255, 161, 30, 0.5);
            padding: 4px 6px;
            text-align: center;
            width: 65px;
            border-radius: 61px;
          }
          .header__left__pending2 {
            font-size: 11px;
            color: #ff461e;
            background: rgba(255, 70, 30, 0.5);
            padding: 4px 6px;
            text-align: center;
            width: 65px;
            border-radius: 61px;
          }
          div {
            display: flex;
            align-items: center;
            img {
              margin-right: 6px;
            }
            p {
              color: #68768f;
              font-size: 11px;
              width: 100px;
            }
            .header__left__initiator {
              width: 140px !important;
            }
          }
        }
      }
    }
    // .header__center__right {
    //   width: 21%;
    //   .header__right__bottom {
    //     padding: 18px 18px 115px 18px;
    //     background: white;
    //     border-radius: 4px;
    //     margin-bottom: 114px;
    //     .header__right__title {
    //       color: $main;
    //       font-size: 15px;
    //       margin-bottom: 21px;
    //     }
    //     div {
    //       display: flex;
    //       margin-bottom: 23px;

    //       align-items: center;
    //       justify-content: space-between;
    //       p {
    //         font-size: 11px;
    //         color: #68768f;
    //       }
    //       .header__right__yellow {
    //         color: $yellow !important;
    //       }
    //     }
    //   }
    //   .header__right__top {
    //     background: rgba(255, 161, 30, 0.26);
    //     border: 1px solid #ffa11e;
    //     border-radius: 4px;
    //     padding: 12px 0 12px 30px;
    //     margin-bottom: 16px;
    //     div {
    //       display: flex;
    //       align-items: center !important;
    //       padding-bottom: 0;
    //       margin-bottom: 0;
    //       p {
    //         font-size: 18px;
    //         font-weight: bold;
    //         margin-right: 72px;
    //         margin-bottom: 5px;
    //       }
    //     }
    //     h5 {
    //       color: $yellow2;
    //       font-size: 20px;
    //       margin: 0 0 8px 0;
    //     }
    //     h6 {
    //       color: $main;
    //       opacity: 69%;
    //       font-size: 10px;
    //     }
    //   }
    //   .header__right__center {
    //     background: white;
    //     padding: 14px 24px 80px 18px;
    //     border-radius: 4px;
    //     margin-bottom: 25px;
    //     h4 {
    //       font-size: 14px;
    //       color: $main;
    //     }
    //     p {
    //       color: $yellow2;
    //       font-size: 11px;
    //       margin: 36px 0 10px 0;
    //     }
    //     .header__right__small__list {
    //       .header__right__small__item {
    //         display: flex;
    //         align-items: center !important;
    //         justify-content: space-between;
    //         margin-bottom: 20px;
    //         div {
    //           display: flex;
    //           align-items: center !important;
    //           p {
    //             margin: 0;
    //           }
    //           .header__right__text {
    //             margin-left: 10px;
    //             margin-right: 2px;
    //             color: #646464;
    //           }
    //           .header__right__data {
    //             color: #56e344;
    //           }
    //           .data__red {
    //             color: #ff5250;
    //           }
    //           .data__yellow {
    //             color: #ffc435;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }
}
</style>
