<template>
  <div>
    <canvas ref="canvas" class="chartjs-render-monitor"></canvas>
  </div>
</template>

<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  name: 'LineChart',
  extends: Line,
  mixins: [reactiveProp],
  props: ['option', 'chartData'],
  mounted() {
    this.renderChart(this.chartData, this.option)
  },
}
</script>

<style scoped></style>
