import Vue from 'vue'
import App from './store/App.vue'
import router from './router'
import store from './store'
import vuetify from '../src/plugins/vuetify'
import ThePagination from '../src/components/UI/ThePagination.vue'
import Paginate from 'vuejs-paginate'
import TheCharts from './components/reports/TheCharts.vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import BaseThedialog from './components/UI/BaseThedialog.vue'
import BaseSpinner from './components/UI/BaseSpinner.vue'
import AreaChart from './components/reports/TheOrders.vue'

import excel from 'vue-excel-export'

Vue.use(excel)
// import dotenv from 'dotenv'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// dotenv.config();
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.component('area-chart', AreaChart)
Vue.config.productionTip = false
Vue.component('base-thedialog', BaseThedialog)
Vue.component('base-spinner', BaseSpinner)

Vue.component('the-pagination', ThePagination)
Vue.component('paginate-the', Paginate)
Vue.component('the-charts', TheCharts)
new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app')
