import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { baseUrl } from '../../src/constants/config'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    totalCashback: 0,
    token: null,
    gross: [],
    loading: false,
    topSearched: [],
    fetched: [],
  },
  getters: {
    getFetch(state) {
      return state.fetched
    },
    topSearchedGet(state) {
      return state.topSearched
    },
    totalCash(state) {
      return state.totalCashback
    },
    isAuthendicated(state) {
      return !!state.token
    },
    isLoading(state) {
      return state.loading
    },
    getGross(state) {
      return state.gross
    },
  },
  mutations: {
    fetchData(state, payload) {
      state.fetched = payload
    },
    muteGross(state, payload) {
      state.gross = payload
    },
    getTotalCashback(state, payload) {
      state.totalCashback = payload
    },
    setUser(state, payload) {
      state.token = payload.access_token
    },
    topSearchedMute(state, payload) {
      state.topSearched = payload
    },
  },
  actions: {
    async fetchingData(context) {
      const token = localStorage.getItem('token')
      await fetch(`${baseUrl}/clients`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then(async (data) => {
          context.commit('fetchData', data.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async topSearched(context, payload) {
      const token = localStorage.getItem('token')
      await axios
        .get(
          `${baseUrl}/reports/top-search/${payload.startTime}/${payload.endTime}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((res) => context.commit('topSearchedMute', res.data))
        .catch((err) => console.log(err))
    },
    async grossSales(context, payload) {
      const token = localStorage.getItem('token')
      this.state.loading = true
      await fetch(`${baseUrl}/reports/sale/${payload.start}/${payload.end}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          from: 23,
          to: 32,
          count_type: payload.type,
          target: 'gross_profit',
          custom: false,
          count: 1,
          organizations: [],
          employees: [],
          end: payload.after || 12,
          services: [],
          start: payload.before || 1,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          context.commit('muteGross', data)
          this.state.loading = false
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    },
    async login(context, payload) {
      const res = await axios
        .post(`${baseUrl}/auth/moderator/login`, {
          user_name: payload.user_name,
          password: payload.password,
        })

        .catch((err) => {
          const error = new Error(err || 'Failed to authendicate.check again')
          throw error
        })
      localStorage.setItem('token', res.data.access_token)
      context.commit('setUser', {
        token: res.data.access_token,
      })
    },
    numberFormat({ commit }, number, dec, dsep, tsep) {
      if (isNaN(number)) return ''
      number = number.toFixed(dec || 0)
      var pindex = number.indexOf('.'),
        fnums,
        decimals,
        parts = []
      if (pindex > -1) {
        fnums = number.substring(0, pindex).split('')
        decimals = (dsep || '.') + number.substr(pindex + 1)
      } else {
        fnums = number.split('')
        decimals = ''
      }
      do {
        parts.unshift(fnums.splice(-3, 3).join(''))
      } while (fnums.length)
      const data = parts.join(tsep || ',') + decimals
      commit('getTotalCashback', data)
    },
  },
  modules: {},
})
