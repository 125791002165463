<script>
import { Line } from 'vue-chartjs'
import { format, subMonths } from 'date-fns'
export default {
  extends: Line,
  data() {
    return {
      gradient: null,
      gradient2: null,
    }
  },
  mounted() {
    this.gradient = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450)
    this.gradient2 = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450)

    this.gradient.addColorStop(0, 'rgba(255, 0,0, 0.5)')
    this.gradient.addColorStop(0.5, 'rgba(255, 0, 0, 0.25)')
    this.gradient.addColorStop(1, 'rgba(255, 0, 0, 0)')

    this.gradient2.addColorStop(0, 'rgba(0, 231, 255, 0.9)')
    this.gradient2.addColorStop(0.5, 'rgba(0, 231, 255, 0.25)')
    this.gradient2.addColorStop(1, 'rgba(0, 231, 255, 0)')

    this.renderChart(
      {
        labels: this.$store.getters.getGross.data.map((cash) =>
          new Date(cash[0]).toLocaleDateString(),
        ),
        datasets: [
          {
            label: 'Cashback',
            backgroundColor: '#66000000',
            borderColor: 'hsl(204, 86%, 53%)',
            borderWidth: 1,
            pointStyle: 'line',
            data: this.$store.getters.getGross.cash_backs.map(
              (chash) => chash[1],
            ),
          },
          {
            label: 'Used-Cashback',
            backgroundColor: '#66000000',
            borderColor: 'hsl(100, 100%, 50%)',
            borderWidth: 1,
            pointStyle: 'line',
            data: this.$store.getters.getGross.used_cashbacks.map(
              (chash) => chash[1],
            ),
          },
        ],
        config: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            labels: {
              usePointStyle: true,
            },
          },
          scales: {
            xAxes: [
              {
                type: 'time',
                time: {
                  displayFormats: {
                    month: 'MMM YYYY',
                  },
                },
              },
            ],
          },
          plugins: {
            zoom: {
              pan: {
                enabled: true,
                mode: 'x',
                rangeMin: {},
                rangeMax: {},
              },
              zoom: {
                enabled: true,
                mode: 'xy',
                drag: false,
                rangeMin: {},
                rangeMax: {},
              },
            },
          },
        },
      },
      { responsive: true, maintainAspectRatio: false },
    )
  },
  methods: {
    computeDonorSummary() {
      //1. fetch donor data
      const donorData = this.fetchDonorData()

      // console.log(JSON.stringify(donorData))
      this.dataRange = `${donorData[donorData.length - 1].month} - ${
        donorData[0].month
      }`

      //2. update table
      this.donorsTable.data = donorData
      this.donorsTable.isLoading = false

      //3. update chart
      this.chartData.datasets.forEach(
        (series) =>
          (series.data = donorData.map((d) => {
            return {
              x: d.timestamp,
              y: d[series.name],
            }
          })),
      )
      this.chartData.isLoading = false
    },
    fetchDonorData() {
      /*  last 24 months with random donor data*/
      const today = new Date()
      return [...Array(this.donorsTable.span)]
        .map((_, i) => subMonths(today, i))
        .map((date) => {
          return {
            timestamp: date,
            month: format(date, 'MMMM yyyy'),
            totalDonors: this.boundedRandom(200, 600),
            newDonors: this.boundedRandom(50, 300),
            revivedDonors: this.boundedRandom(10, 400),
            lostDonors: this.boundedRandom(-10, -300),
          }
        })
        .map((item) => {
          return {
            ...item,
            recruitmentBalance:
              item.newDonors + item.revivedDonors - item.lostDonors,
          }
        })
    },
    resetZoom() {
      this.chartData.resetZoom += 1
    },
    boundedRandom(min, max) {
      /* min inclusive, max exclusive - whole numbers */
      return Math.floor(Math.random() * (max - min) + min)
    },
  },
}
</script>
