<template>
  <section>
    <v-app>
      <div v-if="dialog" @click="closeToggle" class="backdrop"></div>
      <div v-if="dialog2" @click="closeToggle2" class="backdrop"></div>
      <dialog open v-if="dialog2" class="box">
        <h1>Edit</h1>
        <v-row align="center">
          <v-row align="center">
            <v-col cols="6">
              <v-text-field
                label="Title"
                :rules="rules"
                v-model="title2"
                hide-details="auto"
                class="box1"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-select
                v-model="status2"
                :items="items2"
                label="Status"
                item-text="state"
              ></v-select>
            </v-col>
          </v-row>
        </v-row>
        <vue-editor
          v-if="dialog2"
          class="vditor"
          v-model="content2"
        ></vue-editor>
        <div class="chooser__image">
          <div>
            <input
              @change="uploadImage($event)"
              type="file"
              class="custom-file-input"
              id="file"
            />
            <label for="file">choose a Image</label>
          </div>
          <button @click="sentData2" type="button" :disabled="disabledSend">
            Send
          </button>
        </div>
      </dialog>
      <transition>
        <dialog open v-if="dialog" class="box">
          <v-row align="center">
            <v-col cols="6">
              <v-text-field
                label="Title"
                :rules="rules"
                v-model="title"
                hide-details="auto"
                class="box1"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-select
                v-model="status"
                :items="items2"
                label="Status"
                item-text="state"
              ></v-select>
            </v-col>
          </v-row>
          <vue-editor
            v-if="dialog"
            class="vditor"
            v-model="content"
          ></vue-editor>
          <div class="chooser__image">
            <div>
              <input
                @change="uploadImage($event)"
                type="file"
                class="custom-file-input"
                id="file"
              />
              <label for="file">choose a Image</label>
            </div>
            <button @click="sentData" type="button" :disabled="disabledSend">
              Create
            </button>
          </div>
        </dialog>
      </transition>
      <div :class="{ whiteBack: dialog }">
        <div class="products">
          <form @submit.prevent="" class="product__form">
            <div class="product__input">
              <input type="text" placeholder="Search" v-model="search" />
            </div>
          </form>
          <div class="header__right" @click="toggle">
            <img
              src="../../assets/logo3.png"
              alt="logo"
              class="header__right__img"
            />
            <button type="button" class="header__right__text">
              Add notification
            </button>
          </div>
        </div>

        <v-app>
          <v-data-table
            :headers="headers"
            :items="this.items"
            loading-text="Loading... Please wait"
            hide-default-footer
            :loading="loading"
            class="elevation-2"
            @click:row="handleClick"
            :header-props="{ sortIcon: null }"
          >
            <template #[`item.HTML_text`]="{item}">
              <div class="no__wrap" v-html="item.HTML_text">
                {{ item.HTML_text }}
              </div>
            </template>
            <template #[`item.createdAt`]="{item}">
              {{ new Date(item.createdAt).toLocaleDateString() }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="datax">
                <v-icon small readonly @click.stop.prevent="trash(item)">
                  mdi-delete
                </v-icon>
              </div>
            </template>
          </v-data-table>
        </v-app>
        <div class="lds-ring" v-if="loading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <hr class="header__items__line" />
        <the-pagination
          class="pagination"
          @current-page="paginationData"
          @per-page="perPage"
          :total-page2="totalPage"
        ></the-pagination>
      </div>
    </v-app>
  </section>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { baseUrl } from '../../constants/config'
import axios from 'axios'
export default {
  components: { VueEditor },
  watch: {
    select(value) {
      console.log(value)
    },
  },
  data() {
    return {
      select: '',
      select2: '',
      items2: ['active', 'inactive'],
      headers: [
        {
          text: 'Title',
          align: 'start',

          sortable: false,
          value: 'title',
        },
        { text: 'Description', sortable: false, value: 'HTML_text' },
        { text: 'Status', sortable: false, value: 'status' },
        { text: 'Date', sortable: false, value: 'createdAt' },
        { text: 'Type', sortable: false, value: 'type' },
        { text: 'Action', sortable: false, value: 'actions' },
      ],
      desserts: [
        {
          name: 'Frozen Yogurt',
          calories: 200,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: '1%',
        },
        {
          name: 'Ice cream sandwich',
          calories: 200,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          iron: '1%',
        },
        {
          name: 'Eclair',
          calories: 300,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
          iron: '7%',
        },
        {
          name: 'Cupcake',
          calories: 300,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
          iron: '8%',
        },
        {
          name: 'Gingerbread',
          calories: 400,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
          iron: '16%',
        },
        {
          name: 'Jelly bean',
          calories: 400,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
          iron: '0%',
        },
        {
          name: 'Lollipop',
          calories: 400,
          fat: 0.2,
          carbs: 98,
          protein: 0,
          iron: '2%',
        },
        {
          name: 'Honeycomb',
          calories: 400,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
          iron: '45%',
        },
        {
          name: 'Donut',
          calories: 500,
          fat: 25.0,
          carbs: 51,
          protein: 4.9,
          iron: '22%',
        },
        {
          name: 'KitKat',
          calories: 500,
          width: '50%',
          fat: `26323233232323233232323332333332323232333333333333333333333333333`,
          carbs: 65,
          protein: 7,
          iron: '6%',
        },
      ],
      disabledSend: false,
      dialog: false,
      dialog2: false,
      title: '',
      search: '',
      status: '',
      title2: '',
      type: '',
      loading: false,
      currentId: '',
      currentPage: 1,
      content: '',
      content2: '',
      totalPage: 0,
      page: 0,
      perpage: 10,
      image: '',
      form: [],
      data: [],
      items: [
        { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
        { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
        { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
        { age: 38, first_name: 'Jami', last_name: 'Carney' },
      ],
      rules: [
        (value) => !!value || 'Required.',
        (value) => (value && value.length >= 3) || 'Min 3 characters',
      ],
    }
  },
  mounted() {
    this.fetchingData()
  },

  methods: {
    handleClick(value) {
      this.ok(value)
    },
    trash(value) {
      this.postingData3(value)
    },
    ok(value) {
      this.title2 = value.title
      this.content2 = value.HTML_text
      this.currentId = value._id
      this.status2 = value.status
      this.type = value.type
      this.dialog2 = true
    },
    async paginationData(val) {
      this.currentPage = await val
      this.fetchingData()
    },
    async perPage(val) {
      this.perpage = await val
      this.fetchingData()
    },
    async uploadImage(e) {
      this.form = e.target.files[0]
      let formData = new FormData()
      formData.append('method', this.form.method)
      formData.append('file', this.form)
      this.disabledSend = true
      const token = localStorage.getItem('token')
      fetch(`${baseUrl}/banner/image`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        body: formData,
      })
        .then((res) => res.json())
        .then(
          function (response) {
            if (response.success) {
              this.image = response.image
              this.disabledSend = false
            } else {
              //rasim yuklanmadi
              this.disabledSend = false

              // response.json().then(
              //   function (data) {
              //     this.fetchResponse = data
              //   }.bind(this),
              // )
            }
          }.bind(this),
        )
    },
    sentData() {
      this.postingData()
      this.dialog = false
      // this.$router.go()
    },
    async sentData2() {
      await this.postingData2()
      this.dialog2 = false
      this.$router.go()
    },
    async postingData2() {
      const token = localStorage.getItem('token')
      const response = await fetch(`${baseUrl}/banner/${this.currentId}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title: this.title2,
          HTML_text: this.content2,
          status: this.status2,
          type: this.type,
          image: this.image,
        }),
      })

      if (response.status === 200) {
        // updated yozsin
      }
      response.json().then((data) => {
        console.log(data, 'res data')
      })
      return
    },

    async postingData() {
      // console.log(this.image, 'image')
      const token = localStorage.getItem('token')
      const response = await fetch(`${baseUrl}/banner`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          title: this.title,
          HTML_text: this.content,
          status: this.status,
          // type: this.type,
          image: this.image,
        }),
      })

      response.json().then((data) => {
        console.log(data, 'res data')
      })
    },
    async postingData3(value) {
      const token = localStorage.getItem('token')
      const response = await fetch(
        `https://cashback.in1.uz/api/banner/${value._id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      response.json().then((data) => {
        console.log(data, 'res data')
      })
    },
    async fetchingData() {
      this.loading = true
      const token = localStorage.getItem('token')
      await axios
        .get(
          `${baseUrl}/banner?status=all&page=${this.currentPage}&limit=${this.perpage}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((data) => {
          // console.log(data.data.data)
          this.items = data.data.data
          this.totalPage = data.data.total
        })
        .catch((err) => {
          console.log(err)
        })
      this.loading = false
    },
    toggle() {
      this.dialog = !this.dialog
    },
    closeToggle2() {
      this.dialog2 = false
    },
    closeToggle() {
      this.dialog = false
    },
  },
}
</script>
<style scoped lang="scss">
@import '../../sass/_colors.scss';
.datax {
  cursor: pointer;
}
.custom-file-input {
  height: 0;
  overflow: hidden;
  width: 0;
}
.custom-file-input + label {
  background: #ffa11e;

  margin-top: 10px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: 'Rubik', sans-serif;
  font-size: inherit;
  font-weight: 500;
  margin-bottom: 1rem;
  outline: none;
  padding: 1rem 50px;
  transition: all 0.3s;
  vertical-align: middle;
}
.no__wrap {
  max-width: 400px;
  word-wrap: break-word;
  margin: 0 !important;
  position: relative;
  top: 8px;
}
.lds-ring {
  display: flex;
  position: relative;
  justify-content: center;
  position: relative;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #ffa11e;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ffa11e transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.vditor {
  display: inline-block !important;
  height: auto !important;
  padding: 0 20px;
}
table {
  border-top: none !important;
  margin-top: 10px;
}
.product__title {
  margin-left: -175px;
  width: 48%;
}
.product__text {
  margin-left: 40px;
}
.product__title1 {
  margin-left: -130px;
}
.product__image {
  width: 100px;
  height: 50px;
}
.product__date {
  position: relative;
  left: 47px;
}
.product__type {
  position: relative;
  left: 95px;
}
.product__image1 {
  position: relative;
  text-align: end;
}
td,
th {
  text-align: left;
  padding: 8px;
  width: 50%;
  overflow: hidden;
}

tr:nth-child(even) {
  background-color: $grey8;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(54, 55, 64, 0.3);
  backdrop-filter: blur(4px);
  z-index: 10;
}
.box {
  width: 40vw;
  margin: 0 auto;
  background: white;
  box-shadow: 5px 5px 20px rgb(90, 89, 89);
  padding: 10px 20px 10px 20px;
  border-radius: 9px;
  position: fixed;
  overflow: hidden;
  z-index: 999;
  left: 13vw;
  top: 7vw;
  h1 {
    color: white;
    background: $black;
    padding: 12px;
    border-radius: 4px;
    position: relative;
    bottom: 25px;
    font-size: 23px;
  }
  .box1 {
    margin-bottom: 25px;
    width: 15%;
  }
  .box2 {
    border-top: none;
    margin-bottom: 10px;
    outline: none;
    font-size: 17px;
    border-bottom: 1px solid grey;
    border-left: none;
    border-right: none;
    display: block;
    width: 100%;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin-right: 10px;
      margin-top: 15px !important;
    }
  }
  button {
    color: white;

    border: none;
    background: $black;
    padding: 13px;
    cursor: pointer;
    font-family: 'Inter';
    border-radius: 10px;
  }
}
section {
  width: 95%;
  margin: 35px auto 0 auto;
  .second {
    margin-bottom: 15px;
  }

  .first {
    background: $grey8;
    border-radius: 10px !important;
  }
  .products__brands {
    list-style: none;
    display: flex;
    align-items: center !important;
    padding: 28px 0 28px 0;
    // justify-content: space-between;
    .products__brand {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: $grey3 !important;
      font-weight: bold;

      .products__brand__first__text {
        margin: 0 39px 0 45px;
      }
      .products__brand__second__text {
        margin: 0 61px 0 16px;
        .products__brand__third__text {
          margin-right: 28px;
          margin-bottom: 0;
        }
      }
    }
    .products__brand__first__text {
      overflow: hidden !important;
      margin: 0;
      padding: 0;
    }
  }
  .header__items__line {
    border: 1px solid $black;
    opacity: 30%;
  }
  .products {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .header__right {
      background: $yellow;
      display: flex;
      padding: 11px 16px;
      border-radius: 4px;
      cursor: pointer;

      align-items: center;
      button {
        margin-left: 8px;
        color: $white;
        border: none;
        background: transparent;
        font-size: 14px;
      }
    }
    .product__form {
      width: 55%;
      display: flex;
      align-items: center;
      .product__input {
        width: 60%;
        input {
          width: 100%;
          padding: 12px 12px 12px 15px;
          border-radius: 4px;
          border: 1px solid $grey6;
        }
      }
      button {
        background: transparent;
        border: 1px solid $grey6;
        color: $grey7;
        padding: 12px 51px;
        margin-left: 13px;
        border-radius: 4px;
      }
    }
  }
}
</style>
