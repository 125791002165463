import Vue from 'vue'
import VueRouter from 'vue-router'
import TheReport from '../components/reports/TheHome.vue'
// import TheOrders from '../components/TheOrders.vue'
import TheSettings from '../components/settings/TheSettings'
import TheClients from '../components/clients/TheClients.vue'
import TheLogin from '../components/auth/TheLogin.vue'
import TheNotifications from '../components/news/TheProduct.vue'
import TheNavbar from '../views/TheNavbar.vue'
// import TheNotifications from '../components/TheNotifications.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
    component: TheNavbar,
    children: [
      {
        path: '/reports',
        name: 'reports',
        component: TheReport,
      },
      {
        path: '/clients',
        name: 'clients',
        component: TheClients,
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: TheNotifications,
      },
      {
        path: '/settings',
        component: TheSettings,
        name: 'TheSettings',
      },
    ],
  },

  {
    path: '/login',
    component: TheLogin,
    name: 'TheLogin',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
