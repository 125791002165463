import { render, staticRenderFns } from "./TheProduct.vue?vue&type=template&id=573f9fc7&scoped=true&"
import script from "./TheProduct.vue?vue&type=script&lang=js&"
export * from "./TheProduct.vue?vue&type=script&lang=js&"
import style0 from "./TheProduct.vue?vue&type=style&index=0&id=573f9fc7&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "573f9fc7",
  null
  
)

export default component.exports